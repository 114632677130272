<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Productos financieros'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" to="/crear-producto">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Products grid -->
    <v-row>
      <v-col cols="12" md="6">
        <div class="pa-5">
          <p class="text-h6 text-center">Habilitados</p>
          <v-row>
            <v-col v-for="(product, i) in financialProducts.filter((product) => !product.disabled)" :key="i" cols="12" md="6">
              <FinancialProduct :product="product" :editable="true" />
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <div id="div-disabled-products" class="secondary pa-5">
          <p class="text-h6 text-center">Deshabilitados</p>
          <v-row>
            <v-col v-for="(product, i) in financialProducts.filter((product) => product.disabled)" :key="i" cols="12" md="6">
              <FinancialProduct :product="product" :editable="true" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Banner from "@/components/elements/Banner";
import FinancialProduct from "@/components/elements/products/FinancialProduct.vue";

export default {
  name: "Products",
  components: {
    Banner,
    FinancialProduct
  },
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN"]
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      financialProducts: (state) => state.products.financialProducts
    })
  },
  async created() {
    await this.$store.dispatch("products/getFinancialProducts");
  }
};
</script>

<style scoped>
#div-disabled-products {
  height: 100%;
}
</style>
